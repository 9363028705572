import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, HStack, SimpleGrid } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import { useUserJourney } from '../components/UserJourney';
import { WLButton, WLContainer, WLH1, WLLink, WLText } from '../design-library';

interface NotFoundProps extends PageProps<undefined> {}

const NotFoundPage: React.FC<NotFoundProps> = ({ location }) => {
  const path = location?.pathname || '';
  const userJourney = useUserJourney();
  React.useEffect(() => {
    let previousPath;
    try {
      // Record a Google Analytics event, including the current page path and (if available) the path
      // the user navigated from (for detecting broken internal links)
      const previousPage = userJourney?.at(-2)?.page;
      previousPath = previousPage ? new URL(previousPage)?.pathname : undefined;
    } catch {}
    (window as any)?.dataLayer?.push({
      event: `gatsby-not-found`,
      data: {
        path,
        from: previousPath,
      },
    });
  }, [path, userJourney]);

  // Note: Use stubbed path so all 404 pages display standard navbar
  return (
    <Layout seo={{ title: 'Oops!' }} currentPath="/">
      <WLContainer width="NARROW" paddingTop={16} paddingX={{ base: 2, md: 0 }}>
        <SimpleGrid columns={[1, 1, 2]} spacing={4} alignItems="center">
          <QuestionOutlineIcon
            color="blue.700"
            boxSize="12rem"
            justifySelf="center"
          />
          <Box>
            <WLH1>Not Found</WLH1>
            <WLText fontSize="xl">Oops! We couldn't find that page.</WLText>

            <WLText fontSize="lg">
              Try returning to our <WLLink to="/">homepage</WLLink> or use one
              of the links below.
            </WLText>
          </Box>
        </SimpleGrid>
      </WLContainer>

      <WLContainer width="NARROW" paddingY={12} paddingX={{ base: 2, md: 0 }}>
        <WLButton
          variant="solid"
          colorScheme="red"
          href="/getstarted/"
          width="full"
        >
          Get a Quote
        </WLButton>
        <HStack spacing={4} width="full">
          <WLButton
            variant="outline"
            colorScheme="blue"
            href="/contact/"
            width="full"
          >
            Contact Us
          </WLButton>
          <WLButton
            variant="solid"
            colorScheme="blue"
            href="https://member.mywoodmen.org/Member/?_ga=2.203913171.213816262.1643646216-102380624.1635882099"
            width="full"
          >
            My Account
          </WLButton>
        </HStack>
      </WLContainer>
    </Layout>
  );
};

export default NotFoundPage;
